<template>
  <div class="content-index orderDetails">
    <div class="felx-row">
      <span style="margin-bottom:20px;cursor:pointer" @click="back">
        <i class="el-icon-back" style="font-size:18px;color:#909399"></i>
        <span style="font-size: 16px; color:#909399;">
          返回
        </span>
        <span style="font-size: 16px; color: #101010;padding-left:10px">
          订货详情
        </span>
      </span>
      <el-button
        type="primary"
        style="margin-left:20px"
        v-if="detailsData.payStatusDesc =='未支付'&& detailsData.payWayDesc=='收钱吧'"
        @click="payQuery"
        >去查询</el-button
      >
      <el-button
        type="danger"
        style="margin-left:20px"
        v-if="detailsData.payStatusDesc == '未支付'"
        @click="pay"
        >去支付</el-button
      >
    </div>
    <div class="orderDetails-content" v-loading="loading">
      <div class="flex-row" style="padding-top: 10px">
        <span class="title flex-row"><span class="icon"></span> 基本信息</span>
      </div>
      <div class="flex-row" style="padding-top: 10px">
        <div class="flex-row" style="flex: 1; padding-right: 20px">
          <span class="text1">订单号:</span>
          <span class="text2">{{ detailsData.goodsOrderId }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">订单状态:</span>
          <span class="text2">{{ detailsData.orderStatusDesc }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">消费日期:</span>
          <span class="text2">{{ detailsData.orderTime }}</span>
        </div>
      </div>
      <div class="flex-row" style="padding-top: 10px">
        <div class="flex-row" style="flex: 1; padding-right: 20px">
          <span class="text1">支付状态:</span>
          <span class="text2">{{ detailsData.payStatusDesc }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">支付时间:</span>
          <span class="text2">{{ detailsData.paymentTime }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">操作员:</span>
          <span class="text2">{{ detailsData.storeUserName }}</span>
        </div>
      </div>
      <div class="flex-row" style="padding-top: 10px">
        <div class="flex-row" style="flex: 1; padding-right: 20px">
          <span class="text1">用户姓名:</span>
          <span class="text2">{{ detailsData.userName }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">手机号:</span>
          <span class="text2">{{ detailsData.userPhone }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">所属门店:</span>
          <span class="text2">{{ detailsData.storeName }}</span>
        </div>
      </div>
      <div class="flex-row" style="padding: 20px 0 10px">
        <span class="title flex-row"><span class="icon"></span> 消费信息</span>
      </div>
      <div class="table-box">
        <Table
          :header-style="headerStyle"
          :height="''"
          :orientation="'center'"
          :page="true"
          :serial-number="true"
          :table-data="tableData"
          :title-list="titleListData"
        />
      </div>
      <div class="flex-row" style="padding-top: 20px">
        <div class="flex-row" style="flex: 1; padding-right: 20px">
          <span class="text1">商品原价:</span>
          <span class="text2"
            >{{
              detailsData.orderOriginalPrice
                ? detailsData.orderOriginalPrice
                : 0
            }}元</span
          >
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">支付方式:</span>
          <span class="text2">{{ detailsData.payWayDesc }}</span>
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1">优惠金额:</span>
          <span class="text2"
            >{{
              detailsData.discountPrice ? detailsData.discountPrice : 0
            }}元</span
          >
        </div>
      </div>
      <div class="flex-row" style="padding-top: 10px">
        <div class="flex-row" style="flex: 1; padding-right: 20px">
          <span class="text1" style="width:80px">优惠券金额:</span>
          <span class="text2"
            >{{
              detailsData.couponAmount ? detailsData.couponAmount : 0
            }}元</span
          >
        </div>
        <div class="flex-row" style="flex: 1">
          <span class="text1" style="width:80px">优惠券名称:</span>
          <span class="text2">{{ detailsData.couponName }}</span>
        </div>
        <div class="flex-row" style="flex: 1"></div>
      </div>
      <div class="flex-row" style="padding-top: 10px">
        <div class="flex-row" style="flex: 1; padding-right: 20px">
          <span class="text1">订单总额:</span>
          <span class="text2"
            >{{
              detailsData.actuallyPaidPrice ? detailsData.actuallyPaidPrice : 0
            }}元</span
          >
        </div>
        <div class="flex-row" style="flex: 1"></div>
        <div class="flex-row" style="flex: 1"></div>
      </div>
    </div>
    <!--支付页面-->
    <transition name="payFullscreen">
      <div
        class="cardCardCheckoutView"
        v-if="payView"
        v-loading="payBtnLoading"
      >
        <div class="leftView" style="margin-right: 480px">
          <div class="topView">
            <label>支付</label>
            <button class="btn-back" @click="handleCloseCardPay"></button>
          </div>
          <div class="contentView listView" style="height: 620px">
            <div class="orderInfoView">
              <div class="timeView">下单时间：{{ detailsData.orderTime }}</div>
              <div class="toPayView">
                <div class="amtItem subItem">
                  <label class="label-name">
                    <span>
                      原价:￥{{
                        detailsData.orderOriginalPrice
                          ? detailsData.orderOriginalPrice
                          : 0
                      }}
                    </span>
                  </label>
                  <label class="label-name">
                    <span>
                      优惠金额:￥{{
                        detailsData.discountPrice
                          ? detailsData.discountPrice
                          : 0
                      }}
                    </span>
                  </label>
                </div>
                <div class="amtItem subItem">
                  <label class="label-name">
                    <span>
                      优惠券金额: ￥{{
                        detailsData.couponAmount ? detailsData.couponAmount : 0
                      }}
                    </span>
                  </label>
                  <label class="label-name">
                    <span style="color: red">
                      实付金额:￥{{
                        detailsData.actuallyPaidPrice
                          ? detailsData.actuallyPaidPrice
                          : 0
                      }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="paymentView">
              <div class="tView">支付方式</div>
              <div class="listView">
                <div
                  class="paymentItem listItem"
                  v-for="(item, index) in pay_ways"
                  :key="index"
                  :style="item.bool ? 'pointer-events: none' : ''"
                  @click="handlePayWay(item)"
                >
                  <div class="iconView">
                    <!--<img :src="item.ico">-->
                    <label class="label-icon">{{ item.ico }}</label>
                  </div>
                  <div
                    class="textView overflowText"
                    v-if="item.name == '历史余额'"
                  >
                    {{ item.name }}({{
                      userInfo.currStoreBalance == null
                        ? 0
                        : userInfo.currStoreBalance
                    }})
                  </div>
                  <div
                    class="textView overflowText"
                    v-else-if="item.name === '通享卡余额'"
                  >
                    {{ item.name }}({{
                      userInfo.headquartersBalance == null
                        ? 0
                        : userInfo.headquartersBalance
                    }})
                  </div>
                  <div class="textView overflowText" v-else>
                    {{ item.name }}
                  </div>
                  <div class="selectView" v-if="item.active">
                    <img
                      src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_Black.png"
                    />
                  </div>
                  <div
                    class="selectView"
                    style="background:#ccc;opacity: 0.4;"
                    v-if="item.bool"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnView">
            <el-button
              class="btn-settle"
              @click="payBtn"
              v-if="orderData.payWay != 6"
              :loading="buttonLoading"
              type="primary"
              >结账</el-button
            >
          </div>
        </div>
        <div class="rightView" style="width: 480px">
          <div class="defImgView">
            <img
              src="https://static.bokao2o.com/wisdomDesk/images/Def_Bc_OrderCheckout_Right.png"
            />
            <p>{{ orderCheckoutVal }}</p>
          </div>
          <transition name="paidFullscreen">
            <div
              id="qrCodePayPopView"
              class="popView"
              style="width: 480px"
              v-if="payWayView"
              v-loading="qrCodePayView"
              :element-loading-text="qrCodePayViewName"
            >
              <div class="popView-bcView"></div>
              <div
                class="popView-contentView"
                style="width: 450px; top: 0; right: 0; overflow: auto"
              >
                <div class="qrCodePayView" style="height: 794px">
                  <div class="contentView" style="height: 513px">
                    <div class="amtView">
                      ¥
                      <span>{{ detailsData.actuallyPaidPrice }}</span>
                    </div>
                    <div class="imgView">
                      <img src="https://www.shouqianba.com/img/logo.svg" />
                    </div>
                    <div class="qrCodeInputView">
                      <el-input
                        class="input-scanPayCode"
                        placeholder="请扫描或输入付款码"
                        autocomplete="off"
                        ref="pay"
                        v-model="payVal"
                        type="number"
                        autofocus="autofocus"
                      ></el-input>
                      <el-button @click="payBtn" type="primary">收款</el-button>
                    </div>
                    <div class="tipView">
                      请使用支付宝/微信扫码支付
                      <p>支付过程中，请不要关闭该二维码！</p>
                    </div>
                  </div>
                </div>
                <button class="btn-close" @click="payWayView = false"></button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Table from "@/components/table2";
import {
  getGoodsOrderDetail,
  queryPayGoodsOrder,
  payGoodsOrder,
} from "@/api/commodity";
import { getUserInfo } from "@/api/store/user";
export default {
  components: {
    Table,
  },
  props: {},
  data() {
    return {
      tableData: [],
      tableData2: [],
      titleListData: [
        {
          prop: "goodsTypeDesc",
          label: "类型",
        },
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "salePrice",
          label: "单价",
        },
        {
          prop: "goodsNum",
          label: "数量",
        },
        {
          prop: "specification",
          label: "商品规格",
        },
        {
          prop: "currentPrice",
          label: "小计",
        },
      ],
      loading: false,
      detailsData: {},
      goodsIdList: [],
      dialogTableVisible: false,
      headerStyle: {
        background: "#f5f7fa",
        height: "50px",
      },
      detailsId: null,
      payView: false,
      payBtnLoading: false,
      pay_ways: [
        {
          name: "通享卡余额",
          ico: "通",
          label: 11,
          bool: false,
        },
        {
          name: "历史余额",
          ico: "余",
          label: 3,
          bool: false,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
          bool: false,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
          bool: false,
        },
      ],
      orderCheckoutVal: "请选择结账方式",
      // 付款码付款
      qrCodePayView: false,
      // 付款码提示
      qrCodePayViewName: "收款中...",
      payWayView: false,
      payVal: "",
      orderData: {},
      userInfo: {},
      buttonLoading: false,
    };
  },
  created() {
    this.detailsId = this.$route.query.goodsOrderId;
    this.getGoodsOrderDetailData();
  },
  methods: {
    back() {
      this.$router.back();
    },
    // 获取采购列表
    getGoodsOrderDetailData() {
      this.loading = true;
      getGoodsOrderDetail({
        goodsOrderId: this.detailsId,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            this.detailsData = data.data;
            this.tableData = data.data.goodsDetailList;
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    pay() {
      this.payView = true;
      this.refreshData();
    },
    refreshData() {
      getUserInfo({ phoneOrCardNum: this.detailsData.userPhone }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.userInfo = { ...data.data.memberUser };
            this.pay_ways = this.pay_ways.map((item) => {
              if (item.name == "通享卡余额") {
                if (
                  this.userInfo.headquartersBalance == null ||
                  this.userInfo.headquartersBalance == 0
                ) {
                  item.bool = true;
                  return item;
                } else {
                  if (
                    this.detailsData.actuallyPaidPrice >
                    this.userInfo.headquartersBalance
                  ) {
                    item.bool = true;
                    return item;
                  } else {
                    item.bool = false;
                  }
                }
              }
              if (item.name == "历史余额") {
                if (
                  this.userInfo.currStoreBalance == null ||
                  this.userInfo.currStoreBalance == 0
                ) {
                  item.bool = true;
                  return item;
                } else {
                  if (
                    this.detailsData.actuallyPaidPrice >
                    this.userInfo.currStoreBalance
                  ) {
                    item.bool = true;
                    return item;
                  } else {
                    item.bool = false;
                  }
                }
              }
              return item;
            });
          } else {
            this.$message.error(data.msg);
          }
        }
      );
    },
    payQuery() {
      queryPayGoodsOrder({
        outTradeNo: this.detailsData.thirdOrderNum,
        payWay: this.detailsData.payWay,
        goodsOrderId: this.detailsData.goodsOrderId,
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$message.success("查询成功！");
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 关闭支付页面
    handleCloseCardPay() {
      this.payView = false;
      this.orderData = {};
      this.orderCheckoutVal = "请选择结账方式";
      this.payWayView = false;
      this.payVal = "";
      this.pay_ways = [
        {
          name: "通享卡余额",
          ico: "通",
          label: 11,
          bool: false,
        },
        {
          name: "历史余额",
          ico: "余",
          label: 3,
          bool: false,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
          bool: false,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
          bool: false,
        },
      ];
    },
    // 处理点击支付
    handlePayWay(data) {
      this.orderData.payWay = data.label;
      this.pay_ways = this.pay_ways.map((item) => {
        item.active = false;
        if (item.label == data.label) {
          item.active = true;
        }
        return item;
      });
      // 如果选择了余额或者洗后付或现金 不进行弹框 收钱吧弹框
      if (this.orderData.payWay == 6) {
        this.payWayView = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      } else {
        this.payWayView = false;
        this.payVal = "";
        this.orderCheckoutVal =
          "请使用" + data.name + "收款: " + this.detailsData.actuallyPaidPrice;
      }
    },
    // 支付按钮
    payBtn() {
      if (!this.orderData.payWay) {
        this.$message.error("请选择支付方式!");
        return false;
      }
      this.buttonLoading = true;
      this.qrCodePayView = true;
      payGoodsOrder({
        goodsOrderId: this.detailsData.goodsOrderId,
        authCode: this.orderData.payWay === 6 ? this.payVal : null,
        payWay: this.orderData.payWay,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            if (this.orderData.payWay == 6) {
              this.queryPay({
                outTradeNo: data.data.outTradeNo,
                payWay: this.orderData.payWay,
                goodsOrderId: this.detailsData.goodsOrderId,
              });
              this.$message.success("支付成功!");
            } else {
              this.$message.success(data.data);
              this.handleCloseCardPay();
              this.getGoodsOrderDetailData();
            }
          } else {
            this.$message.error(data.msg);
          }
          this.buttonLoading = false;
          this.qrCodePayView = false;
        })
        .catch(() => {
          this.buttonLoading = false;
          this.qrCodePayView = false;
        });
    },
    // 查询是否支付
    queryPay(data) {
      queryPayGoodsOrder({
        ...data,
      }).then(({ data }) => {
        if (data.code != 0) {
          this.$message.error(data.msg);
        }
        this.handleCloseCardPay();
        this.getGoodsOrderDetailData();
      });
    },
    handlePay() {
      if (this.payVal) {
        this.qrCodePayView = true;
        this.payOrder();
      } else {
        this.qrCodePayView = false;
        this.$message.error("请读入或输入付款码");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.orderDetails {
  padding: 20px;
}
::v-deep .v-modal {
  z-index: 99 !important;
}
.orderDetails-content {
  padding: 0 20px 20px;
  height: calc(100vh - 160px);
  overflow: auto;
  .title {
    flex: 1;
    font-size: 16px;
    font-weight: 700;
    .icon {
      height: 14px;
      width: 4px;
      background-color: #353bfe;
      border-radius: 20px;
      margin-right: 5px;
    }
  }
  .text2 {
    font-size: 14px;
    flex: 1;
    color: #101010;
  }
  .text1 {
    font-size: 14px;
    width: 70px;
  }
}
.flex-row {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box {
  overflow: hidden;
  text-overflow: ellipsis;
  /* 将对象作为弹性伸缩盒子模型显示 */
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
  -webkit-line-clamp: 3;
  /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
}
::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  left: 70px;
  width: calc(100vw - 70px);
  height: 100%;
  background: #f8f8f8;
  z-index: 100;

  button {
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
        background-position: left center;
        background-size: 28px;
        background-repeat: no-repeat;
      }

      button {
        border: none;
        outline: none;
        box-sizing: border-box;
        background-color: #fff;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 1;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 100px;
            height: 100px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }

              > .label-icon {
                display: inline-block;
                border-radius: 6px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Medium;
                color: #fff;
                text-align: center;
                background: rgba(66, 153, 255, 0.7);
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        font-size: 16px;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png)
      left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}
#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          /* width: 320px; */
          /* line-height: 40px; */
          /* height: 40px; */
        }

        > button {
          width: 72px;
          height: 40px;
          font-size: 12px;
          font-family: PingFangSC-Semibold;
          margin-left: 15px;
          border-radius: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}
//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}
</style>
